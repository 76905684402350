exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-black-yeti-js": () => import("./../../../src/pages/about-black-yeti.js" /* webpackChunkName: "component---src-pages-about-black-yeti-js" */),
  "component---src-pages-celery-mountain-js": () => import("./../../../src/pages/celery-mountain.js" /* webpackChunkName: "component---src-pages-celery-mountain-js" */),
  "component---src-pages-designer-insights-js": () => import("./../../../src/pages/designer-insights.js" /* webpackChunkName: "component---src-pages-designer-insights-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politik-js": () => import("./../../../src/pages/politik.js" /* webpackChunkName: "component---src-pages-politik-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

